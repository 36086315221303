<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title"><i class="fab fa-weixin"></i> Chat</h3>                            
                </div>
                <div class="card-body">
                    
                    <DataTable 
                        stripedRows
                        v-model:filters="filters" 
                        :value="chatsFiltrados" 
                        paginator 
                        :rows="10" 
                        dataKey="id_chat" 
                        :loading="loading"
                        :globalFilterFields="['fecha_visita', 'codigo_servicio', 'texto', 'nombre_usuario', 'nombre_tramitador', 'nombre_compania']">
                        
                        <!-- Header Template -->
                        <template #header>
                            <div class="flex justify-content-end">
                                <div class="row justify-content-end align-items-center">
                                    <div>
                                        <label for="buscar">Buscar: </label>
                                    </div>
                                    <div style="margin-left: 10px;">
                                        <input type="search" name="buscar" class="form-control" v-model="globalFilter" aria-controls="tabla_chat"> 
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #empty> No hay mensajes disponibles. </template>
                        <!-- Column Definitions -->
                        <Column :sortable="false">
                            <template #header>
                                <div class="d-flex justify-content-between">
                                    <strong>Fecha/Hora</strong>
                                    <button @click="toggleOrden" :aria-sort="ordenAscendente ? 'ascending' : 'descending'" class="btn btn-sm">
                                        <i :class="icon"></i>
                                    </button>
                                </div>
                            </template>
                            <template #body="{ data }">
                                {{ data.fecha_visita }}
                            </template>
                        </Column>
                        
                        <Column field="codigo_servicio" header="Código" style="min-width: 12rem">
                            <template #body="{ data }">
                                <span href="#" class="clickable-text" @click="abrirChat(data)">{{ data.codigo_servicio }}</span>
                            </template>
                        </Column>
                        
                        <Column field="texto" header="Mensaje" style="min-width: 12rem">
                            <template #body="{ data }">
                                {{ data.texto }}
                            </template>
                        </Column>
                        
                        <Column :sortable="false">
                            <template #header>
                                <select v-model="tramitadorSeleccionado">
                                    <option value="">Tramitador</option>
                                    <option v-for="tram in tramitadores" :key="tram.id" :value="tram.nombre">{{ tram.nombre }}</option>
                                </select>
                            </template>
                            <template #body="{ data }">
                                {{ data.nombre_tramitador }}
                            </template>
                        </Column>
                        
                        <Column :sortable="false">
                            <template #header>
                                <select v-model="companiaSeleccionada" @change="filtrarCompania">
                                    <option value="">Compañía</option>
                                    <option v-for="comp in companias" :key="comp.id" :value="`${comp.nombre}-${comp.prefijo}`">{{ comp.nombre }} - {{ comp.prefijo }}</option>
                                </select>
                            </template>
                            <template #body="{ data }">
                                {{ data.nombre_compania }}
                            </template>
                        </Column>
                        
                        <Column field="leido" style="min-width: 6rem">                            
                            <template #body="{ data }">
                                <div style="display: grid; justify-content: center;">
                                    <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="marcado=data.id_chat" data-toggle="modal" data-target="#confirmarLido">Leído</button>
                                </div>
                                <div class="modal fade" id="confirmarLido" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">PWGS</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                ¿Marcar como leído?
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                                <button type="button" class="btn btn-primary" @click="marcarLeido(marcado)" data-dismiss="modal">OK</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            </template>
                        </Column>
                    </DataTable>    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PwgsApi } from '../../services/PwgsApi';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';

export default {
    data() {
        return {
            marcado:'',
            direcciones: {},
            chats: [],
            urlapu : localStorage.getItem('direccion'),
            tramitadorSeleccionado: '',
            tramitadores: [],
            companias: [],
            companiaSeleccionada: '',
            ordenAscendente: false,
            ordenarPorFecha: true,
            loading: true,
            globalFilter: null,
            icon:'fas fa-sort-up',
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            }
        };
    },
    components: {
        DataTable,
        Column,
    },
    computed: {
        chatsFiltrados() {
            let filtrados = this.chats;

            if (this.tramitadorSeleccionado) {
                filtrados = filtrados.filter(chat => chat.nombre_tramitador === this.tramitadorSeleccionado);
            }

            if (this.companiaSeleccionada) {
                filtrados = filtrados.filter(chat => chat.nombre_compania === this.companiaSeleccionada);
            }

            return filtrados.sort((a, b) => {
                const fechaA = this.parseDate(a.fecha_visita);
                const fechaB = this.parseDate(b.fecha_visita);
                return this.ordenAscendente ? fechaA - fechaB : fechaB - fechaA;
            });
        }
    },
    methods: {
        abrirChat(datos){
            var url  = "https://"+this.urlapu+datos.url;
            window.open(url, "Chat", "width=800, height=600");
        },
    
        parseDate(dateString) {
            // Separamos la fecha y la hora
            const [datePart, timePart] = dateString.split(' ');

            // Separamos día, mes y año
            const [day, month, year] = datePart.split('/');

            // Formateamos en ISO 8601: yyyy-MM-ddTHH:mm:ss
            return new Date(`${year}-${month}-${day}T${timePart}`);
        },
        toggleOrden() {
            this.ordenAscendente = !this.ordenAscendente;
        },
        cargarDirecciones() {
            for (var i in this.chats) {
                const ide = this.chats[i].id_chat;
                const direc = 'https://' + localStorage.getItem('direccion') + this.chats[i].url;
                this.direcciones[ide] = direc;
            }
            this.loading=false;
        },
        async cargarCompanias() {
            const api = new PwgsApi();
            const resp = await api.get('/companias/simple?sortField=nombre&sortOrder=1');
            this.companias = resp.datos;
        },
        async cargarChats() {
            const api = new PwgsApi();
            const resp = await api.get('chat-pwgs');
            this.chats = resp.datos;
            this.cargarDirecciones();
        },
        async cargarTramitadores() {
            const api = new PwgsApi();
            const resp = await api.get('/usuarios/Gestión?sortField=nombre&sortOrder=1');
            this.tramitadores = resp.datos;
        },        
        async filtrarCompania() {
            const api = new PwgsApi();
            if (!this.companiaSeleccionada) {
                this.cargarChats();
            } else {                
                const resp = await api.get('chat-pwgs?nombre_compania='+this.companiaSeleccionada);
                this.chats = resp.datos;
                this.cargarDirecciones();
            }
        }
    },
    mounted() {
        this.direccion = localStorage.getItem('direccion');
        this.cargarChats();
        this.cargarTramitadores();
        this.cargarCompanias();
    },
    watch: {
        globalFilter(newFilter) {
            this.filters.global.value = newFilter;
        }
    }
}
</script>

<style scoped>
.clickable-text {
    cursor: pointer;
    color: blue;
    user-select: text;
}
.clickable-text:hover {
    color: darkblue;
}
</style>